<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content" v-if="!viLoader">
      <div class="webNew-dispflex">
       
        <div class="backbox">
      <div class="back-arrow" @click="previousRoute">
        <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
     
      
      </div>

        </div>
        <h1 class="hdrtitle backHeading" style="margin-top: 0px;">
          Inbox
        </h1>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
           
            <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%; background: none">
                <input type="radio" name="tabs" id="leaveApproval" checked="checked" />
                <label for="leaveApproval" style="border: 1px solid #C0C4E9;border-radius: 15px 15px 0px 0px;">Leave Approval</label>
                <div class="tab"> <leaveRequest/> </div>
                <input
                  type="radio"
                  name="tabs"
                  id="visitorTracking"
                  
                />
                <label for="visitorTracking" style="border: 1px solid #C0C4E9;border-radius: 15px 15px 0px 0px;">Visitor Tracking</label>
                <div class="tab">

                  <visitorTracking/>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  id="certificateRequest"
                />
                <label for="certificateRequest" style="border: 1px solid #C0C4E9;border-radius: 15px 15px 0px 0px;">Certificate Request</label>
                <div class="tab">

                  <certificateRequest/>
                </div>
               
                <input type="radio" name="tabs" id="attendanceRegularization" />
                <label for="attendanceRegularization" style="border: 1px solid #C0C4E9;border-radius: 15px 15px 0px 0px;"
                  >Attendance Regularization</label
                >
                <div class="tab">
                  <div class="row" v-if="attendanceList.length > 0">
                    <div class="col-6 my-2">
                      <div class="row">
                        <div
                          class="col-12 my-2"
                          v-for="(item, index) in attendanceList"
                          :key="index"
                        >
                          <div
                            class="widjet"
                            style="width: 100%"
                            @click="openDetailedView(item)"
                          >
                            <div class="widjethdr dispflex">
                              <div style="width: 100%" class="flexitm">
                                <span
                                  class="iconsect blubg"
                                  style="padding-left: 11px"
                                  ><i class="fas fa-user-tie"></i
                                ></span>
                                <span style="font-size: 1.1rem">{{
                                  item.createdBy
                                }}</span>
                                <span
                                  style="
                                    color: grey;
                                    font-size: 0.75rem;
                                    margin-left: 10rem;
                                  "
                                  >{{ item.createdDate | date }}</span
                                >
                                <!-- {{ Math.floor(Math.abs(new Date() - item.createdDate) / (1000 * 60 * 60)) }} -->
                                <div style="font-size: 0.85rem">
                                  Time Logs Adjustment -
                                  {{ item.clockInTime | date }}
                                </div>
                                
                                <div
                                  style="
                                    font-size: 0.65rem;
                                    color: grey;
                                    margin-left: 3.3rem;
                                  "
                                >
                                  {{ item.notes }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 my-2">
                      <div class="widjet" v-if="isDetailedViewClicked">
                        <div class="widjethdr dispflex">
                          <div style="width: auto" class="flexitm">
                            <span
                              class="iconsect blubg"
                              style="padding-left: 11px"
                              ><i class="fas fa-user-tie"></i>
                            </span>
                            <h1>{{ selectedAttendance.createdBy }}</h1>
                          </div>
                        </div>
                        <div class="p-3 widjetcontent">
                          <div class="row">
                            <div class="col-6 my-2">
                              <i
                                style="font-size: xx-large; color: gray"
                                class="far fa-calendar-alt"
                              ></i>
                              <span style="margin-left: 7px">{{
                                selectedAttendance.clockInTime | date
                              }}</span>
                              <div style="font-size: 0.85rem;margin-top: 1.1rem;">
                                  Clock In Time-
                                 {{ selectedAttendance.newClockedInTimeValue  }}
                                </div>
                                <div style="font-size: 0.85rem;margin-top: 1.1rem;">
                                  Clock Out Time-
                                  {{  selectedAttendance.newClockedOutimeValue   }}
                                </div>
                              <!-- <div style="margin-left: 33px;">Personal Emergency</div> -->
                            </div>
                            <div class="col-6 my-2"></div>
                           
                            <input
                              type="text"
                              disabled
                              :value="selectedAttendance.notes"
                              class="form-control form-input mx-3"
                            />
                          </div>

                          <div class="row">
                            <div class="col-12 my-2">
                              <span class="has-float-label">
                                <input
                                  type="text"
                                  name="comment"
                                  class="form-control form-input"
                                  id="comment"
                                  placeholder="Add Comment or Reject Reason here"
                                />
                                <label for="comment">Comment</label>
                              </span>
                            </div>
                          
                          </div>
                          <div class="row">
                            <div class="col-6 my-2">
                             
                            </div>
                            <div class="col-3 my-2">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                style="width: 100%; background-color: green"
                                @click.prevent="approveAttendance"
                              >
                                Approve
                              </button>
                            </div>
                            <div class="col-3 my-2">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                style="width: 100%; background-color: red"
                                @click.prevent="rejectAttendance()"
                              >
                                Reject
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-12 my-2" style="text-align: center;">
                      <span style="margin-right: 116px;color: darkgoldenrod;"> -- No Attendance Regularization -- </span>
                     
                  </div>
                </div>
                  <!-- <div class="tbllist mt-1">
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">User Name</div>
                        <div class="cell">Clock In Time</div>
                        <div class="cell">Clock Out Time</div>
                        <div class="cell">Is Absent</div>
                        <div class="cell">Notes</div>
                        <div class="cell">Action</div>
                      </div>
                      <div
                        class="resrow"
                        v-for="(attendance, index) in attendanceList"
                        :key="index"
                      >
                        <div class="cell" data-title="Created By">
                          {{ attendance.createdBy }}
                        </div>
                        <div class="cell" data-title="Clock In Time">
                          {{ attendance.clockInTime }}
                        </div>
                        <div class="cell" data-title="Clock Out Time">
                          {{ attendance.clockOutTime }}
                        </div>
                        <div class="cell" data-title="Is Absent">
                          {{ attendance.isAbsent }}
                        </div>
                        <div class="cell" data-title="Notes">
                          {{ attendance.notes }}
                        </div>
                        <div class="cell" data-title="Action">
                          <b-dropdown
                            id="dropdown-1"
                            text=""
                            right
                            style="margin-right: 3px"
                          >
                            <b-dropdown-item>Approve</b-dropdown-item>
                            <b-dropdown-item>Reject</b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
                <input type="radio" name="tabs" id="archive" />
                <label for="archive" style="border: 1px solid #C0C4E9;border-radius: 15px 15px 0px 0px;">Archive</label>
                <div class="tab">
                  <div class="row">
                    <div class="col-3 my-2"></div>
                    <div class="col-3 my-2" style="margin-left: 150px">
                      <span class="has-float-label">
                        <select
                          class="form-control form-input"
                          id="typeList"
                          @change="getAllAttendanceList(selectedType)"
                          v-model="selectedType"
                        >
                        <option :value="null">-- Select --</option>

                          <option
                            v-for="(item, index) in typeList"
                            :key="index"
                            :value="item.value"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                        <label for="clslistid">Type </label>
                      </span>
                    </div>
                    <!-- <div class="col-3 my-2">
                      <span class="has-float-label">
                        <select
                          class="form-control form-input"
                          id="deptlistid"
                          @change="sortAttendanceUsingStatus(selectedStatus)"
                          v-model="selectedStatus"
                        >
                          <option
                            v-for="(item, index) in allStatusList"
                            :key="index"
                            :value="item.name"
                          >
                            {{ item.value }}
                          </option>
                        </select>
                        <label for="clslistid">Status </label>
                      </span>
                    </div> -->
                    <div class="col-3 my-2"></div>
                  </div>
                  <div class="tbllist mt-1" v-if="selectedType == 'Leave'">
                  <div class="restable">
                    <div class="resrow resheader">
                      <!-- <div class="cell">Term</div> -->
                      <div class="cell" style="width: 24%;">Staff Name</div>
                      <div class="cell">From </div>
                      <div class="cell">To</div>
                      <div class="cell">No Of Days</div>
                      <div class="cell">Status</div>
                      <div class="cell">Action</div>

                    </div>
                    <div
                  class="resrow"
                  v-for="(item, index) in sortedAttendanceList"
                  :key="index"
                >
                  <div class="cell">{{ item.staffDetail ? item.staffDetail.firstName : '' }}</div>
                 
                  <div class="cell">{{ item.from | dateFormat }}</div>
                  <div class="cell">{{ item.to | dateFormat }}</div>
                  <div class="cell">{{ item.noOfDates.length }}</div>
                  
                  <div class="cell" style="color: rgb(0, 255, 55);">{{ item.status || '' }}</div>
                  <div class="cell" data-title="Action">
                    <b-dropdown  
                      id="dropdown-1"
                      text=""
                      right
                    
                      style="margin-right: 3px"
                    >
                    <b-dropdown-item 
                       @click.prevent="confirmDelete(item)"
                      
                        >Cancel</b-dropdown-item
                      >
                      
                     
                    </b-dropdown>
                    <!-- <b-dropdown  v-if="data.status == 'Submitted' || data.status == 'Approved'"
                      id="dropdown-1"
                      text=""
                      right
                      style="margin-right: 3px"
                    >
                    <b-dropdown-item v-if="data.status == 'Submitted'"
                       @click.prevent="updateVisitor(data,'Approved')"
                       
                        >Approve</b-dropdown-item
                      >
                      <b-dropdown-item v-if="data.status == 'Submitted'"
                       @click.prevent="updateVisitor(data,'Rejected')"
                       
                        >Reject</b-dropdown-item
                      >
                      <b-dropdown-item v-if="data.status == 'Approved' || data.status == 'Rejected'"
                       @click.prevent="updateVisitor(data,'Completed')"
                       
                        >Completed</b-dropdown-item
                      >
                     
                    </b-dropdown> -->
                    <!-- <button    
                                    class="btn round red tooltipt"
                                    @click.prevent="confirmDelete(item)"
                                  >
                                    <i class="far fa-trash-alt"></i>
                                    <span class="tooltiptext">Delete</span>
                                  </button> -->
                   
                  </div>
                  
                </div>
                  
                  </div>
                  
                </div>
                  <div class="tbllist mt-1" v-if="selectedType == 'Regularize'">
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">Name</div>
                        <div class="cell">Date</div>
                        <div class="cell">Status</div>
                      </div>
                      <div
                        class="resrow"
                        v-for="(attendance, index) in sortedAttendanceList"
                        :key="index"
                      >
                      
                        <div class="cell" data-title="Created By">
                          {{ attendance.createdBy || ''}}
                        </div>
                        <div class="cell" data-title="Is Absent">
                          {{ attendance.createdDate | dateFormat }}
                        </div>
                        <div class="cell" data-title="Notes">
                          Approved
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tbllist mt-1" v-if="selectedType == 'Student'">
                  <div class="restable">
                    <div class="resrow resheader">
                      <!-- <div class="cell">Term</div> -->
                      <div class="cell" style="width: 24%">Student Name</div>
                      <div class="cell">Class</div>
                      <div class="cell">Classroom</div>
                      <div class="cell">From </div>
                      <div class="cell">To</div>
                      <div class="cell">No Of Days</div>
                      <!-- <div class="cell">Applied Date</div> -->
                      <div class="cell">Status</div>
                      <!-- <div class="cell">Action</div> -->
                    </div>
                    <div
                  class="resrow"
                  v-for="(item, index) in sortedAttendanceList"
                  :key="index"
                >
                  <div class="cell">{{ item.studentName ? item.studentName : '' }}</div>
                  <div class="cell">{{ item.class ? item.class.className : '' }}</div>
                  <div class="cell">{{ item.section ? item.section.name : '' }}</div>

                  <div class="cell">{{ item.from | dateFormat }}</div>
                  <div class="cell">{{ item.to | dateFormat }}</div>
                  <div class="cell">{{ item.noOfDates.length }}</div>
                  
                  <!-- <div class="cell">{{ item.createdDate | dateFormat }}</div> -->
                  <div class="cell" style="color: rgb(0, 255, 55);">{{ item.status || '' }}</div>
                 
                </div>
                  
                  </div>
                  
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vi-spinner
      v-if="viLoader"
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="
        width: 100%;
        z-index: 111;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 1rem;
      "
    />
  </div>
</template>
<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";

export default {
  name: "ViAdmissionAttendanceRegularization",
  components: {
    ViSpinner,
  },
  data() {
    return {
      userDetails: {},
      attendanceList: [],
      allAttendanceList: [],
      sortedAttendanceList: [],
      viLoader: false,
      currentTab: 0,
      skip: 0, // set the skip info
      limit: 1000, // set the limit info
      currentPageNumber: 1,
      lastPageNumber: 1,
      selectedAttendance: {},
      allStatusList: [
        {
          name: "None",
          value: "None",
        },
        {
          name: "Pending",
          value: "Pending",
        },
        {
          name: "Approved",
          value: "Approved",
        },
        {
          name: "Rejected",
          value: "Rejected",
        },
      ],
      typeList: [
        {
          name: "Attendance Regularization",
          value: "Regularize",
        },
        {
          name: "Staff Leave Request",
          value: "Leave",
        },
        {
          name: "Student Leave Request",
          value: "Student"
        }
      ],
      selectedType: null,
      selectedStatus: "",
      isDetailedViewClicked: false,
    };
  },

  created() {
    this.getAttendanceList();
    //this.getAllAttendanceList();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    getUserName() {
      let userData = secureUI.sessionGet("user");
      return (
        userData.userDetails.firstName + " " + userData.userDetails.lastName
      );
    },
  },
  methods: {
    initComponent() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        this.userDetail = userData.userDetails;
       
      }
    },
    previousRoute() {
      let data = localStorage.getItem("previousTab");
      localStorage.setItem("activeTab", data);
      localStorage.setItem("previousTab", data);
      this.$router.go(-1);
    },
    setActiveTab(index) {
      this.currentTab = index;
    },
    async getAttendanceList() {
      try {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXPost(
            "/user/attendance/pending/list",
            { skip: 0, limit: 1000 },
            userData.token
          );

          if (response.isSuccess) {
            this.attendanceList = secureUI.secureGet(response.data) || [];
          } else {
            this.$toasted.error(response.message);
              if(response.message == 'Your session has expired, please login'){
                    localStorage.removeItem('user');
                    this.$router.push('/login');
            }
           // console.log("in else ==> ");
          }
        }
      } catch (err) {
        this.$toasted.error(err);
       // console.log("in catch error ==> ", err);
      }
    },
    async getAllAttendanceList(actionType) {
      try {
        this.allAttendanceList =  [];
        this.sortedAttendanceList = [];
        if(actionType){
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXPost(
            `/user/attendance/all/list`,
            { skip: this.skip, limit: this.limit,type: actionType },
            userData.token
          );

          if (response.isSuccess) {
            this.allAttendanceList = secureUI.secureGet(response.data) || [];
            this.sortedAttendanceList = secureUI.secureGet(response.data) || [];
          } else {
            console.log("in else ==> ");
          }
        }
       }

      } catch (err) {
        console.log("in catch error ==> ", err);
      }
    },
    // async sortAttendanceUsingType(selectedType) {
    //   if (selectedType) {
    //     this.sortedAttendanceList = this.allAttendanceList.filter(
    //       (x) => x.type === selectedType
    //     );
    //   } else {
    //     this.sortedAttendanceList = this.allAttendanceList;
        
    //   }
    // },
    // async sortAttendanceUsingStatus(selectedStatus) {
    //   if (selectedStatus == "None") {
    //     this.sortedAttendanceList = this.allAttendanceList;
    //   } else {
    //     this.sortedAttendanceList = this.allAttendanceList.filter(
    //       (x) => x.status === selectedStatus
    //     );
    //   }
    // },
    openDetailedView(item) {
      this.isDetailedViewClicked = true;
      this.selectedAttendance = { ...item };
    },
    async approveAttendance() {
      try {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          const body = {
           
            clockedInTimeLocalValue : this.selectedAttendance.newClockedInTimeLocalValue || null,
            clockedInTimeValue : this.selectedAttendance.newClockedInTimeValue || null,
            clockedOutimeValue : this.selectedAttendance.newClockedOutimeValue || null,
            clockedOutTimeLocalValue : this.selectedAttendance.newClockedOutTimeLocalValue || null,
            previousStatus: this.selectedAttendance.previousStatus || '',
            status: "Approved",
          };
          
          const response = await ViService.viXPut(
            `/user/attendanceAction/regularize/${this.selectedAttendance._id}`,
            body,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success("Approved Successfully");
            this.isDetailedViewClicked = false;
            this.getAttendanceList();
            //this.getAllAttendanceList();
          } else {
            this.$toasted.error(response.message);
          }
        }
      } catch (err) {
        this.$toasted.error(
          err && err.message ? err.message : "Internal Server Error"
        );
      }
    },
    async confirmDelete(data) {
        try {
          let userData = secureUI.sessionGet("user");
  
          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push("/login");
          } else {
       
           const response = await ViService.viXPost("/home/leaveCancel/staff",data,userData.token);
          
            if (response.isSuccess) {
              this.getAllAttendanceList(this.selectedType)
              this.$toasted.success("Deleted Successfully");
            } else {
              this.$toasted.error(response.message);
            }
          }
        } catch (err) {
          this.$toasted.error(
            err && err.message ? err.message : "Internal Server Error"
          );
        }
      },
    async rejectAttendance() {
      try {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          const body = {
           
            status: "Rejected",
          };

          const response = await ViService.viXPut(
            `/user/attendanceAction/regularize/${this.selectedAttendance._id}`,
            body,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success("Rejected Successfully");
            this.isDetailedViewClicked = false;
            this.getAttendanceList();
           // this.getAllAttendanceList();
          } else {
            this.$toasted.error(response.message);
          }
        }
      } catch (err) {
        this.$toasted.error(
          err && err.message ? err.message : "Internal Server Error"
        );
      }
    },
  },
  components: {
    certificateRequest: () => import("./ViOnlineTc.vue"),
    leaveRequest: () => import("../Inbox/VileaveApproval.vue"),
    visitorTracking: () => import("../Profile/VisitorTracking.vue")

  },
};
</script>
