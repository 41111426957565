<template>
    <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
		<div id="page-content" v-if="!viLoader">
			 <!-- <div class="dispflex">
        <h1 class="hdrtitle" style="margin-top: 0px;">Promote Students</h1>
 <button type="button" class="backbtn" @click="previousRoute">
          <i class="fa fa-arrow-left"></i>
        </button>
        </div> -->
		<div class="webNew-dispflex"   >
       
       <!-- <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Promote Students
       </h4>
      </div>
		  <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%; background: none">
                <input  @click="setActiveTab(1)"
				  :checked="currentTab == 1 ? 'checked' : ''"
                  type="radio"
                  name="tabs"
                  id="academictab"
                />
                <label for="academictab" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">PROMOTE</label>
                <div class="tab">
                  <form data-vv-scope="promotevalidate" id="stuprofile" accept-charset="utf-8">
	    	<div class="row mt-2">	
						<div class="col-md-12 col-lg-12 col-sm-12">
							<div class="widjet m-2">
								<div class="widjetcontent mt-1 p-3">
									<div class="form-row ">
										<div class="form-group input-group col-md-6 mt-3 ">						
											<span class="has-float-label">
												<select
												
												@change="setNextAcdYear(promote.currentAcdYear)"
													v-model="promote.currentAcdYear"
													class="form-control form-input"
													id="acyead"
													v-validate="'required'"
													name="currentAcd"
												>
													<option :value="null">-- Select --</option>
													<option
													v-for="(item, index) in currentAcademicYearList"
													:key="index"
													:value="item._id"
													>
													{{ item.from }} - {{ item.to }}
													</option>
												</select>
												<label
														v-if="errors.first('promotevalidate.currentAcd')"
														class="vi-error"
														style="top: -1em"
														>Current Academic Year is required</label
													>
												<label v-else for="acyearid"
													>Current Academic Year <span class="required">*</span></label
												>
												
											</span>
										</div>
										<div class="form-group input-group col-md-6 mt-3 ">						
											<span class="has-float-label">
												<select
											    	:disabled="!promote.currentAcdYear"
													v-model="promote.nextAcdYear"
													class="form-control form-input"
													id="acyearid"
													v-validate="'required'"
													name="nextAcdYesr"
												>
													<option :value="null">-- Select --</option>
													<option
													v-for="(item, index) in nextAcademicYearList"
													:key="index"
													:value="item._id"
													>
													{{ item.from }} - {{ item.to }}
													</option>
												</select>
												<label
														v-if="errors.first('promotevalidate.nextAcdYesr')"
														class="vi-error"
														style="top: -1em"
														>Next Academic Year is required</label
													>
												<label v-else for="acyearid"
													>Next Academic Year <span class="required">*</span></label
												>
												
											</span>
										</div>
										<div class="form-group input-group col-md-3 mt-3 ">						
											<span class="has-float-label">
												<select 
												   :disabled="!promote.currentAcdYear"
													@change="setToClassAndSection(promote.fromClass)"
													v-model="promote.fromClass"
													class="form-control form-input"
													id="acyearid"
													v-validate="'required'"
													name="fromCLass"
												>
													<option :value="null">-- Select --</option>
													<option
													v-for="(item, index) in fromClassList"
													:key="index"
													:value="item._id"
													>
													{{ item.className }}
													</option>
												</select>
												<label
														v-if="errors.first('promotevalidate.fromCLass')"
														class="vi-error"
														style="top: -1em"
														>From Class is required</label
													>
												<label v-else for="acyearid"
													>From Class <span class="required">*</span></label
												>
												
											</span>
										</div>
										<div class="form-group input-group col-md-3 mt-3 ">						
											<span class="has-float-label">
												<select
													@change="clearValues()"
												   :disabled="!promote.fromClass"
													v-model="promote.fromSection"
													class="form-control form-input"
													id="acyearid"
													v-validate="'required'"
													name="fromSection"
												>
													<option :value="null">-- Select --</option>
													<option
													v-for="(item, index) in fromSectionList"
													:key="index"
													:value="item._id"
													>
													{{ item.name }}
													</option>
												</select>
												<label
														v-if="errors.first('promotevalidate.fromSection')"
														class="vi-error"
														style="top: -1em"
														>From Section is required</label
													>
												<label v-else for="acyearid"
													>From Section <span class="required">*</span></label
												>
												
											</span>
										</div>
										<div class="form-group input-group col-md-3 mt-3 ">						
											<span class="has-float-label">
												<select
												:disabled="!promote.fromClass"
												@change="setToSection(promote.toClass)"
													v-model="promote.toClass"
													class="form-control form-input"
													id="acyearid"
													v-validate="'required'"
													name="toClass"
												>
													<option :value="null">-- Select --</option>
													<option
													v-for="(item, index) in toClassList"
													:key="index"
													:value="item._id"
													>
													{{ item.className }}
													</option>
												</select>
												<label
														v-if="errors.first('promotevalidate.toClass')"
														class="vi-error"
														style="top: -1em"
														>To Class is required</label
													>
												<label v-else for="acyearid"
													>To Class <span class="required">*</span></label
												>
												
											</span>
										</div>
										<div class="form-group input-group col-md-3 mt-3 ">						
											<span class="has-float-label">
												<select
													:disabled="!promote.toClass"
													v-model="promote.toSection"
													class="form-control form-input"
													id="acyearid"
													v-validate="'required'"
													name="toSection"
												>
													<option :value="null">-- Select --</option>
													<option
													v-for="(item, index) in toSectionList"
													:key="index"
													:value="item._id"
													>
													{{ item.name }}
													</option>
												</select>
												<label
														v-if="errors.first('promotevalidate.toSection')"
														class="vi-error"
														style="top: -1em"
														>To Section is required</label
													>
												<label v-else for="acyearid"
													>To Section <span class="required">*</span></label
												>
												
											</span>
										</div>
										
										<div class="form-group input-group col-md-6 mt-3  pr-md-3" v-if="totalStudent > 0">						
											<span class="has-float-label">
												<input type="text" disabled :value="totalStudent" name="billdate"  class="form-control form-input" id="billdateid" placeholder="No. of Students " >
												
												<label  for="billdateid">Total No.of Students </label>
											</span>
										</div>
										<div class="form-group input-group col-md-6 mt-3  pr-md-3" v-if="feesPendingStudentList.length > 0">						
											<span class="has-float-label">
												<input type="text" disabled  name="billdate"  class="form-control form-input" id="billdateid" placeholder="No. of Students " >
												<span  style="font-weight: 600;color: red;" @click="openPopUpForStudentView"><a>{{ feesPendingStudentList.length }} - Students</a> </span>
												
												<label  for="billdateid">No. of Students Fees Pending</label>
											</span>
										</div>
										<!-- <div class="form-group input-group col-md-6 mt-3  pr-md-3" v-if="studentList.length > 0">						
											<span class="has-float-label">
												<input type="text" disabled :value="studentList.length" name="billdate"  class="form-control form-input" id="billdateid" placeholder="No. of Students " >
												
												<label  for="billdateid">No. of Students Fees Paid</label>
											</span>
										</div> -->
										<div class="form-group input-group col-md-6 mt-3  pr-md-3" v-if="countSelectedStudents > 0">						
											<span class="has-float-label">
												<input type="text" disabled :value="countSelectedStudents" name="billdate"  class="form-control form-input" id="billdateid" placeholder="No. of Students " >
												
												<label  for="billdateid">No. of Students selected for promoted</label>
											</span>
										</div>
										
									</div>							
								</div>
								
							</div>
						</div>
			</div>
			<div class="row mt-2" v-if="studentList.length > 0">	
				<div class="col-md-12 col-lg-12 col-sm-12">
					<div class="widjet m-2">
					<div class="widjetcontent">
				
				<div class="tbllist mt-1">
					<div class="restable">
					<div class="resrow resheader">
						<!-- <div class="cell" style="width: 10%">Select</div> -->
						<div class="cell chkbox" data-title="Select">
						<label class="custcheckbox">
							<input type="checkbox" :disabled="studentList.length == 0" @click="selectAllStudents(selectAll)" v-model="selectAll"/>
							<span class="checkmarkchk"></span>
						</label>
						</div>
						<div class="cell">Student Name</div>
						<div class="cell">From Class</div>
						<div class="cell"> From Section</div>
						<div class="cell">Current Academic Year</div>
					</div>
					<div
						class="resrow"
						v-for="(student, index) in studentList"
						:key="index"
					>
						<div class="cell chkbox" data-title="Select">
						<label class="custcheckbox">
							<input type="checkbox"  v-model="student.isSelected"  />
							<span class="checkmarkchk"></span>
						</label>
						</div>
						<div class="cell" data-title="Student Name">
						{{ student.firstName + " " + student.lastName }}
						</div>
						<div class="cell" data-title="Class">
						{{ student.class ? getClassName(student.class) : '' }}
						</div>
						<div class="cell" data-title="Class">
						{{ student.classSection ? getSectionName(student.classSection) : '' }}
						</div>
						<div class="cell" data-title="Class">
						{{ student.academicYear ? getacdYearName(student.academicYear) : '' }}
						</div>
					
					
					</div>
					</div>
				</div>
				</div>
					</div>
				</div>
				
			</div>
			<div class="row mt-2" v-if="studentList.length > 0 && countSelectedStudents > 0">	
				<div class="col-md-12 col-lg-12 col-sm-12">
					<div class="widjet m-2">
						
						<div class="widjetfooter text-right dkicon">							
								<button   class="btn btnsml" style="width:auto" @click.prevent="promoteStudents">
							<span>Promote Students</span></button>
								<button type="button" @click="$router.go(-1)" class="btn btncl clsmdl">
								   <span>Cancel</span></button>
							</div>
					</div>
					
				</div>
			</div>
		</form>
                </div>
                <input  @click="setActiveTab(2)" type="radio" name="tabs" id="leads" :checked="currentTab == 2 ? 'checked' : ''" />
                <label for="leads" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">PROMOTE HISTORY</label>
                <div class="tab">
                  <div id="page-content">
                   
                   <div class="row mt-2" >	
				<div class="col-md-12 col-lg-12 col-sm-12">
					<div class="widjet m-2">
					<div class="widjetcontent">
				
				<div class="tbllist mt-1">
					<div class="restable">
					<div class="resrow resheader">
						<!-- <div class="cell" style="width: 10%">Select</div> -->
					
						
						<!-- <div class="cell">From Class</div>
						<div class="cell"> From Section</div> -->
						<div class="cell">From Academic Year</div>
						<div class="cell">To Academic Year</div>
						<div class="cell">From Class</div>
						<div class="cell">To Class</div>
						<div class="cell">No of Students</div>
						<div class="cell">Action</div>
					</div>
					<div
						class="resrow"
						v-for="(data, index) in promoteHistoryList"
						:key="index"
					>
					
						
						
						<div class="cell" data-title="Class">
						{{ data.currentAcdYear ? getacdYearName(data.currentAcdYear) : '' }}
						</div>
							<div class="cell" data-title="Class">
						{{ data.nextAcdYear ? getacdYearName(data.nextAcdYear) : '' }}
						</div>
						<div class="cell" data-title="Class">
						{{ data.fromClass ? getClassName(data.fromClass) : '' }}
						</div>
						<div class="cell" data-title="Class">
						{{ data.toClass ? getClassName(data.toClass) : '' }}
						</div>
						<div class="cell" data-title="Class">
						{{ data.students.length }}
						</div>
						<button class="btn round orange tooltipt" @click="openViewPopUp(data._id)">
                                   <i class="far fa-eye"></i><span class="tooltiptext">View</span>
                          </button>
					
					
					</div>
					</div>
				</div>
				</div>
					</div>
				</div>
				
			</div>
                  </div>
                </div>
              
              </div>
            </div>

      <div> 
		
      </div>
    </div>
    <b-modal id="viewstudpop" class="modal" no-close-on-backdrop no-close-on-esc>
		<button type="button" class="close"  @click.prevent="closeStudentViewPopUp">×</button>
		<div class="contact-form">
       <div class="row mt-2">	
				<div class="col-md-12 col-lg-10 col-sm-12">
					<h2>Fees Pending Students</h2> 
				</div>
				<!-- <div class="col-md-12 col-lg-2 col-sm-12">
					<button type="button" @click.prevent="closeStudentViewPopUp" class="btn btncl clsmdl">
				   <span>Cancel</span></button>
				</div> -->
			</div>
        <br />
        <div style="height: 350px; overflow-y: auto">
         <div class="tbllist mt-1">
                <div class="restable">
                  <div class="resrow resheader">
                    <!-- <div class="cell" style="width: 10%">Select</div> -->
					<div class="cell">S.No</div>
                    <div class="cell">Student Name</div>
					<div class="cell">Class</div>
					<div class="cell">Section</div>
					<div class="cell">Academic Year</div>
                  </div>
                  <div
                    class="resrow"
                    v-for="(student, index) in feesPendingStudentList"
                    :key="index"
                  >
                    <div class="cell" data-title="Student Name">
                      {{ index + 1 }}
                    </div>
                    <div class="cell" data-title="Student Name">
                      <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="redirectRoute(`/admission/student/view/details/${student._id}`)">{{ student.firstName + " " + student.lastName }} </a>
                    </div>
					<div class="cell" data-title="Class">
						{{ student.class ? getClassName(student.class) : '' }}
					</div>
					<div class="cell" data-title="Class">
						{{ student.classSection ? getSectionName(student.classSection) : '' }}
					</div>
					<div class="cell" data-title="Class">
						{{ student.academicYear ? getacdYearName(student.academicYear) : '' }}
					</div>
                  </div>
                </div>
        </div>
			
        </div>
      </div>
    </b-modal>
	 <b-modal id="viewPromoteHistory" class="modal" no-close-on-backdrop no-close-on-esc>
		<button type="button" class="close"  @click.prevent="closePromoteView">×</button>
      <div class="contact-form">
         <!-- <form data-vv-scope="promotevalidate" id="stuprofile" accept-charset="utf-8"> -->
	    	<div class="row mt-2">	
						<div class="col-md-12 col-lg-12 col-sm-12">
							<div class="widjet m-2">
								<div class="widjetcontent mt-1 p-3">
									<div class="form-row ">
										<div class="form-group input-group col-md-6 mt-3 ">						
											<span class="has-float-label">
												 <input
													type="text"
													disabled
													name="studentFirstName"
													v-model="promoteHistoryView.currentAcdYear"
													class="form-control form-input"
													id="stdFirstName"
												/>
												
												<label for="acyearid"
													>Current Academic Year </label
												>
												
											</span>
										</div>
										<div class="form-group input-group col-md-6 mt-3 ">						
											<span class="has-float-label">
												 <input
													type="text"
													disabled
													name="studentFirstName"
													v-model="promoteHistoryView.nextAcdYear"
													class="form-control form-input"
													id="stdFirstName"
												/>
												<label  for="acyearid"
													>Next Academic Year <span class="required">*</span></label
												>
												
											</span>
										</div>
										<div class="form-group input-group col-md-3 mt-3 ">						
											<span class="has-float-label">
												 <input
													type="text"
													disabled
													name="studentFirstName"
													v-model="promoteHistoryView.fromClass"
													class="form-control form-input"
													id="stdFirstName"
												/>
												<label for="acyearid"
													>From Class <span class="required">*</span></label
												>
												
											</span>
										</div>
										<div class="form-group input-group col-md-3 mt-3 ">						
											<span class="has-float-label">
												 <input
													type="text"
													disabled
													name="studentFirstName"
													v-model="promoteHistoryView.fromSection"
													class="form-control form-input"
													id="stdFirstName"
												/>
												<label  for="acyearid"
													>From Section <span class="required">*</span></label
												>
												
											</span>
										</div>
										<div class="form-group input-group col-md-3 mt-3 ">						
											<span class="has-float-label">
											 <input
													type="text"
													disabled
													name="studentFirstName"
													v-model="promoteHistoryView.toClass"
													class="form-control form-input"
													id="stdFirstName"
												/>
												<label for="acyearid"
													>To Class <span class="required">*</span></label
												>
												
											</span>
										</div>
										<div class="form-group input-group col-md-3 mt-3 ">						
											<span class="has-float-label">
											 <input
													type="text"
													disabled
													name="studentFirstName"
													v-model="promoteHistoryView.toSection"
													class="form-control form-input"
													id="stdFirstName"
												/>
												<label for="acyearid"
													>To Section <span class="required">*</span></label
												>
												
											</span>
										</div>
										
									</div>							
								</div>
								
							</div>
						</div>
			</div>
			<div class="row mt-2" v-if="promoteHistoryView.students.length > 0">	
				<div class="col-md-12 col-lg-12 col-sm-12">
					<div class="widjet m-2">
					<div class="widjetcontent">
				
				<div class="tbllist mt-1">
					<div class="restable">
					<div class="resrow resheader">
						<!-- <div class="cell" style="width: 10%">Select</div> -->
						<div class="cell" data-title="Select">
						 S.no
						</div>
						<div class="cell">Student Name</div>
					</div>
					<div
						class="resrow"
						v-for="(student, index) in promoteHistoryView.students"
						:key="index"
					>
						<div class="cell chkbox" data-title="Select">
						{{ index + 1 }}
						</div>
						<div class="cell" data-title="Student Name">
						<a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="redirectRoute(`/admission/student/view/details/${student._id}`)">{{ student.firstName + " " + student.lastName }} </a>

						</div>
					
					
					</div>
					</div>
				</div>
				</div>
					</div>
				</div>
				
			</div>
			<div class="row mt-2">	
				<div class="col-md-12 col-lg-12 col-sm-12">
					<div class="widjet m-2">
						
						<div class="widjetfooter text-center dkicon">							
								
								<button type="button" @click="closePromoteView" class="btn btncl clsmdl">
								   <span>Close</span></button>
							</div>
					</div>
					
				</div>
			</div>
		<!-- </form> -->
      </div>
    </b-modal>
	
	 <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
    />
    </div>
</template>
<script>

import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "vi-adhocFees",
  data() {
    return {
      viLoader: false,
      promote: {
        fromClass: null,
		fromSection: null,
		currentAcdYear: null,
		toClass: null,
		toSection: null,
		nextAcdYear: null,
	  },
	  promoteHistoryList: [],
	  feesPendingStudentList: [],
      fromClassList: [],
	  fromSectionList: [],
	  toSectionList: [],
	  toClassList: [],
      currentAcademicYearList: [],
	  nextAcademicYearList: [],
	  studentList: [],
      selectAll: false,
	  selectAcademicYear: null,
	  totalStudent: 0,
	  currentTab: 1,
	   skip: 0, // set the skip info
      limit: 10, // set the limit info
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
      totalCount: [],
	  promoteHistoryView: {
        fromClass: null,
		fromSection: null,
		currentAcdYear: null,
		toClass: null,
		toSection: null,
		nextAcdYear: null,
		students: []
	  },
    };
  },
  created() {
	  this.getClassList();
	  this.getAcademicYearList();
	  this.getPromoteHistoryList()
  },
 computed: {
	styles(){
	var brwHeight = window.innerHeight;
	return{
		height: (brwHeight - 90) + 'px'
	};
	},
    countSelectedStudents() {
     if(this.studentList.length > 0){
       const selectedCount = this.studentList.filter( x => x.isSelected)
	   return selectedCount.length
	 }else{
		 return 0
	 }}
    },
  methods: {
	    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",this.$route.path);
     this.$router.push({ path: data });
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
	clearValues(){
	 this.studentList = []
	 this.totalStudent = 0
	 this.feesPendingStudentList = []
	 this.getStudentsList();
	},
	getClassName(classid){
      const classDetails =  this.fromClassList.find((x) => x._id == classid);
      if(classDetails){
        return classDetails.className
      }else{
        return ''  
      }
      
    },
	getSectionName(secid){
      const sectDetails =  this.fromSectionList.find((x) => x._id == secid);
      if(sectDetails){
        return sectDetails.name
      }else{
        return ''  
      }
      
    },
	getacdYearName(acdid){
      const acdYearDetails =  this.currentAcademicYearList.find((x) => x._id == acdid);
      if(acdYearDetails){
        return acdYearDetails.from + '-' + acdYearDetails.to  
      }else{
        return ''  
      }
      
    },
	selectAllStudents(value){
    if(value){
		this.studentList.forEach((x, i) => {
					x.isSelected = false;
				});
	}else{
		this.studentList.forEach((x, i) => {
					x.isSelected = true;
				});
	}		
	},
	openPopUpForStudentView(){
        this.$bvModal.show("viewstudpop");
	},
	closeStudentViewPopUp(){
        this.$bvModal.hide("viewstudpop");
	},
	
	setToClassAndSection(classId){
		this.totalStudent = 0
	    this.feesPendingStudentList = []
		if(classId){
		  this.fromSectionList = []
		  this.toClassList = []
          const filterByToClass = this.fromClassList.filter( x => x._id != classId)
          this.toClassList = filterByToClass
		  const findByClassIdSections = this.fromClassList.find( x => x._id == classId)
		  this.fromSectionList = findByClassIdSections.sections
		}else{
			this.fromSectionList = [],
			this.toSectionList = [],
			this.toClassList = [],
			this.promote.fromSection = null,
			this.promote.toClass = null,
			this.promote.toSection = null
		}
	},
	setNextAcdYear(acdYearId){
		this.studentList = []
	    this.totalStudent = 0
	    this.feesPendingStudentList = []
		this.promote.fromSection = null,
	    this.promote.fromClass = null

		if(acdYearId){
		  this.nextAcademicYearList = []
          const filterByToClass = this.currentAcademicYearList.filter( x => x._id != acdYearId)
          this.nextAcademicYearList = filterByToClass
		}else{
			this.nextAcademicYearList = [],
			this.promote.nextAcdYear = null,
			this.studentList = []
		}

	},
	
	setToSection(toclassId){
		if(toclassId){
		  this.toSectionList = []
		  const findByClassIdSections = this.toClassList.find( x => x._id == toclassId)
		  this.toSectionList = findByClassIdSections.sections
		}else{
			this.toSectionList = [],
			this.promote.toSection = null
		}
	},
	
	async getClassList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
         this.$router.push('/login');
      } else {
	// 	if (userData.userDetails.profile.name == "Teacher") {
    //     const response = await ViService.viXGet(
    //       "/teacher/getAssignedTeacherwithClassSectionList?viewType=list",
    //       userData.token
    //     );

    //     if (response.isSuccess) {
    //     //  const resultData = secureUI.secureGet(response.data);
	// 	  const classResult = secureUI.secureGet(response.data);
	// 	  this.fromClassList = classResult.data
    //     } else {
    //       this.$toasted.error(response.message);
    //     }
    //    }else{
        const response = await ViService.viXGet(
          "/class/getAllClassesAndSection?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
			const classResult = secureUI.secureGet(response.data);
			this.fromClassList = classResult
            this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
		   if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
	 // }
      }
    },
	async openViewPopUp(id) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
         this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/contact/getPromoteHistoryStudents?id=${id}`,
          userData.token
        );
        if (response.isSuccess) {
			const result = secureUI.secureGet(response.data);

			this.promoteHistoryView.fromClass = result.promoteDetails.fromClass.className,
			this.promoteHistoryView.fromSection = result.promoteDetails.fromSection.name,
			this.promoteHistoryView.currentAcdYear = result.promoteDetails.currentAcdYear.from + " - " + result.promoteDetails.currentAcdYear.to,
			this.promoteHistoryView.toClass = result.promoteDetails.toClass.className,
			this.promoteHistoryView.toSection = result.promoteDetails.toSection.name,
			this.promoteHistoryView.nextAcdYear = result.promoteDetails.nextAcdYear.from + " - " + result.promoteDetails.nextAcdYear.to,
			this.promoteHistoryView.students = result.studentList || []

			this.$bvModal.show("viewPromoteHistory");
        } else {
          this.$toasted.error(response.message);
		   if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
	closePromoteView(){
      this.$bvModal.hide("viewPromoteHistory");
	},
	
	setActiveTab(index){
      this.currentTab = index
    },
	async getStudentsList(){
	  this.studentList = []
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
         this.$router.push('/login');
      } else {
	
        const response = await ViService.viXGet(`/contact/getAllPromoteStudents?acdYear=${this.promote.currentAcdYear}&classId=${this.promote.fromClass}&sectionId=${this.promote.fromSection}`,userData.token);
        if (response.isSuccess) {
			 const studentResult = secureUI.secureGet(response.data);
             this.totalStudent = studentResult.length
			 this.feesPendingStudentList = []
			 if(studentResult.length > 0){
				 this.studentList = studentResult.filter( x => x.isFeesPending == false)
				 this.feesPendingStudentList = studentResult.filter( x => x.isFeesPending == true)
			 }

			
        } else {
          this.$toasted.error(response.message);
		   if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
	},
	 async promoteStudents() {
      let isFormValid = false;
      let message = "Please fill all the required details";

      await this.$validator.validateAll("promotevalidate").then((result) => {
        isFormValid = result;
      });

      if(isFormValid){
        if(this.studentList.length > 0){

		  const selectedStudentList = this.studentList.filter( x => x.isSelected)

		  if(selectedStudentList.length > 0){
                let userData = secureUI.sessionGet("user");

				if(!userData){
				 this.$toasted.error("Please login and do the action");
				} else {

					let obj = {
                      promotedDetails: this.promote,
					  selectedList : selectedStudentList
					}

				   const response = await ViService.viXPost("/contact/update/promoted/students",obj,userData.token );

					if (response.isSuccess) {
						this.$toasted.success(response.message);
						this.currentTab = 2
						this.promote.fromClass = null,
						this.promote.fromSection = null,
						this.promote.currentAcdYear = null,
						this.promote.toClass = null,
						this.promote.toSection = null,
						this.promote.nextAcdYear = null,
						this.fromSectionList = [],
						this.toSectionList = [],
						this.toClassList = [],
						this.studentList = []
						this.totalStudent = 0
						this.feesPendingStudentList = [],
						this.getPromoteHistoryList()
					} else {
						this.$toasted.error(response.message);
						 if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
					}
				}

		  }else{
			  this.$toasted.error('Please select minimum student');
		  }
		}else{
			this.$toasted.error('Please select promote students');
		}
       
      } else {
        this.$toasted.error(message);
      }
    },
	async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
		   this.viLoader = true
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
		
        if (response.isSuccess) {
          this.currentAcademicYearList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
		   if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
		 this.viLoader = false
      }
    },
	async getPromoteHistoryList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
		   this.viLoader = true
	   const response = await ViService.viXGet(`/contact/getPromoteHistoryList?academicYear=${this.selectAcademicYear}&skip=${this.skip}&limit=${this.limit}&isCount=${this.countAll}`,userData.token);

        // const response = await ViService.viXGet(
        //   "/contact/getPromoteHistoryList",
        //   userData.token
        // );
		
        if (response.isSuccess) {
			 const resultData = secureUI.secureGet(response.data);
           this.promoteHistoryList = resultData.promoteHistoryList
         
           if(this.countAll){
            this.totalCount = resultData.totalCount
            this.lastPageNumber = Math.ceil(this.totalCount.length/this.limit);
           }
        //   this.promoteHistoryList = secureUI.secureGet(response.data);
        //   this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
		   if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
		 this.viLoader = false
      }
    },
	
	getSelectAcademicYear(){
		this.adhocFeeList = []
          if(this.selectAcademicYear){
            
            const filterByAdhocList = this.allAdhocList.filter( x => x.academicYear == this.selectAcademicYear)
            this.adhocFeeList = filterByAdhocList
          }else{
			  this.adhocFeeList = this.allAdhocList
		  }
    },
  },
  components: {
      ViSpinner
    }
};
</script>